import moment from "moment";
import { format } from "date-fns";
import { FOLDERNAMES } from "app/variables";
import { toast } from 'react-toastify';

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition() {
  if (!window) {
    return;
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);
  if (!elmID || !elm) {
    return;
  }
  var startY = currentYPosition();
  var stopY = elmYPosition(elm);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference =
    moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(date, "DD/MM/YYYY HH:mm:ss")
    ) / 1000;

  if (difference < 60) return `${Math.floor(difference)} seconds`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} days`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} months`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf("?") + 1)
  );
  var definitions = search.split("&");
  definitions.forEach(function (val, key) {
    var parts = val.split("=", 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(" ");
}

export function initCodeViewer() {
  if (!document) return;
  const pre = document.getElementsByTagName("pre");
  if (!pre.length) return;
  Array.prototype.map.call(pre, (p) => {
    // console.log(p);
    p.classList.add("collapsed");
    p.addEventListener("click", (e) => {
      console.log(e.target);
      e.target.classList.remove("collapsed");
    });
  });

  // pre.map(p => {
  //   console.log(p)
  // })
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const getFormattedTranslationData = (translationData, selectedLang) => {
  const language = {'en': 'English', 'no': 'Norwegian'}
  let data = {};
  translationData.map((item) => {
    data[item.translationCode] = item.translation.filter((lang) => lang.language === language[selectedLang])[0].translationValue;
  });
  return data;
}

export const validatedEmail = (emailAddress) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(emailAddress)
}
export const getHourDifference = (date1, date2) => {
  const diffInMs = date1 - date2;
  const diffInHour = Math.floor(diffInMs/1000/60/60);
  return diffInHour;
}

export const getDisplayNameForMail = (message, folderName) => {
  if (FOLDERNAMES.SENT.includes(folderName) || FOLDERNAMES.DRAFT.includes(folderName)) {
    let name = message.toRecipients.map((recipient, index) => {
      return <p className='m-0' data-toggle='tooltip' data-placement='top' title={recipient.emailAddress.address}>{recipient.emailAddress.name}{index+1 !== message?.toRecipients?.length ? "," : ""}</p>;
    });
    return name;
  }
  else {
    return <p className='m-0' data-toggle='tooltip' data-placement='top' title={message?.from?.emailAddress?.address}>{message?.from?.emailAddress?.name}</p>
  }
}

export const getTextFromHtml = (html) => {
  var divContainer= document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export const modifyContentForMail = (content, message) => {
  if (content) {
    try {
      // creating html for reply text
      var infoText = document.createElement("div");
      infoText.innerHTML = `<p>From: ${
        message?.from?.emailAddress?.address
      }</p> 
      <p>To: ${message?.toRecipients[0]?.emailAddress.address}</p>
      <p>On: ${format(
        new Date(message?.createdDateTime).getTime(),
        "dd MMM, yyyy"
      )}`;

      var replyText = document.createElement("div");
      replyText.innerHTML = content;
      let elementToProof = replyText.querySelector(".elementToProof");
      if (elementToProof) {
        elementToProof.style.cssText = "color: purple;";
        elementToProof.insertAdjacentHTML("afterbegin", infoText.innerHTML);
        return replyText.innerHTML;
      } else {
        let elementToProof = document.createElement("div");
        elementToProof.style.cssText = "padding-top: 50px; color: purple;";
        elementToProof.innerHTML = infoText.innerHTML + replyText.innerHTML;
        return elementToProof.outerHTML;
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  }
  return "";
};

export const modifyContentForReplyMail = (content, message) => {
  const htmlContent = modifyContentForMail(content, message);
  return "<div style='padding-top:10px'></div>" + htmlContent;
};

export const modifyContentForForwardMail = (content, message) => {
  try {
    const htmlContent = modifyContentForMail(content, message);
    const wrapperElement = document.createElement("div");
    wrapperElement.innerHTML = htmlContent;
    const forwardedText = document.createElement("p");
    forwardedText.textContent = "-----------Forwarded Message-----------";
    forwardedText.style = 'color: purple';
    wrapperElement?.insertBefore(
      forwardedText,
      wrapperElement.childNodes[0]
    );
    return "<div style='padding-bottom:40px'></div>" + wrapperElement.outerHTML;
  }
  catch (err) {  
    console.log(err);
    toast.error(err);
    return "<div style='padding-bottom:40px'></div>";
  }
  
};

export const previewLocalSignature = (signature, userName) => {
  let companyVariable = JSON.parse(sessionStorage.getItem('companyVariable'))??[];
  let nameSpanRegex = new RegExp("<span[^>]*>%NAVN%</span>", "g");
  let nameRegex = new RegExp("%NAVN%", "g");

  let postRegex = new RegExp("%FFEPOST%", "g");
  let postSpanRegex = new RegExp("<span[^>]*>%FFEPOST%</span>", "g");

  let telefomRegex = new RegExp("%FFTELEFON%", "g");
  let telefomSpanRegex = new RegExp("<span[^>]*>%FFTELEFON%</span>", "g");

  let forretinRegex = new RegExp("%FORRETNINGSFØRER%", "g");
  let forretinSpanRegex = new RegExp("<span[^>]*>%FORRETNINGSFØRER%</span>", "g");

  let forOslahRegex = new RegExp("%FORRETNINGSF&Oslash;RER%", "g");
  let forOslahSpanRegex = new RegExp("<span[^>]*>%FORRETNINGSF&Oslash;RER%</span>", "g");


   // Remove <span> tag around variable if present
  signature = signature.replace(nameSpanRegex, "%NAVN%");
  signature = signature.replace(postSpanRegex, "%FFEPOST%");
  signature = signature.replace(telefomSpanRegex, "%FFTELEFON%");
  signature = signature.replace(forretinSpanRegex, "%FORRETNINGSFØRER%");
  signature = signature.replace(forOslahSpanRegex, "%FORRETNINGSF&Oslash;RER%");

  signature = signature.replace(nameRegex, userName);
  if (companyVariable.length > 0) {
    signature = signature.replace(postRegex, companyVariable[0].BMMail);
    signature = signature.replace(telefomRegex, companyVariable[0].BMPhone);
    signature = signature.replace(forretinRegex, companyVariable[0].BMName);
    signature = signature.replace(forOslahRegex, companyVariable[0].BMName);
  }
  return signature
}

export const getDefaultSignature = () => {
  return `<div><p>Med vennlig hilsen</p><p><span style="color: rgb(104, 29, 168)">%NAVN%</span> for</p><p>${sessionStorage.getItem('companyName')}</p><br><p>Spørsmål om faktura og regnskap rettes til forretningsfører:</p><p><span style="color: rgb(104, 29, 168)">%FORRETNINGSFØRER%</span>,<span style="color: rgb(104, 29, 168)">%FFEPOST%</span>,<span style="color: rgb(104, 29, 168)">%FFTELEFON%</span></p></div>`;
}

export const getDefaultAutoReplyContent = () => {
  return `<div><p>Din epost er mottatt! Vi sjekker epost jevnlig og svarer innen få virkedager.</p><br> <p>Om det gjelder faktura, ta kontakt med</p><p>#FORRETNINGSFØRER#<br>#FFEPOST#, #FFTELEFON#.</p><br><p>Du kan også logge på Nabonett for å finne din egen faktura, oppslag fra</p><p>styret, selskapsdokumenter og mer.</p></div>`
}